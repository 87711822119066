@layer components {
  .collapse1 {
    &.collapsing {
      position: relative;
      height: 0;
      overflow: hidden;
      transition: height 0.35s ease 0s;
    }

    &:not(.show, .collapsing) {
      display: none;
    }

    &.show {
    }
  }
}