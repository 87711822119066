@layer components {
  .btn {
    animation: none !important;
  }

  // ==========================================
  // Standard Buttons
  // ==========================================
  .btn-action,
  .btn-action-secondary {
    @apply h-[4.8rem] md:h-[4rem] font-normal w-full text-2xl md:text-xl rounded-md;
    line-height: 0;
  }

  .btn-primary-light {
    @apply btn-primary;
    @apply brightness-[1.15] saturate-[0.9] text-neutral-200;
  }

  .btn-neutral {
    @apply text-neutral-200;

    &.btn-outline {
      @apply text-neutral-700;

      &:hover {
        @apply text-neutral-200;
      }
    }
  }

  // ==========================================
  // Amazon
  // ==========================================
  .btn-amazon {
    border-color: #b39a5a;
    box-shadow: 0 1px 0 rgba(255, 255, 255, 0.4) inset;
    background: linear-gradient(to bottom, #f7dfa5, #f0c14b);
    color: #333;
    border-style: solid;
    border-width: 1px;

    &:hover,
    &:disabled {
      color: #333;
      border-color: #b39a5a;
      background: linear-gradient(to bottom, #f5d78e, #eeb933);
    }
  }

  .btn-amazon-flat {
    @apply btn-primary;
    @apply bg-amazon-500 border-amazon-500 text-neutral-700;

    &:hover,
    &:disabled {
      @apply bg-amazon-600 border-amazon-600;
    }
  }

  .btn-amazon-gray {
    background: linear-gradient(to bottom, #f7f8fa, #e7e9ec);
    border-color: #b7b8bb;
    border-style: solid;
    border-width: 1px;
    box-shadow: 0 1px 0 rgba(255, 255, 255, 0.6) inset;
    color: #333;

    &:hover, &:disabled {
      color: #333;
      border-color: #b7b8bb;
      background: linear-gradient(to bottom, #e7eaf0, #d9dce1);
    }
  }

  // ==========================================
  // Theme Application
  // ==========================================
  html[data-action-buttons="primary-light"],
  html[data-action-buttons="initial"] {
    .btn-action,
    .btn-action-flat {
      @apply btn-primary-light;
    }

    .btn-action-secondary {
      @apply btn-neutral;
    }
  }

  html[data-action-buttons="amazon"] {
    .btn-action {
      @apply btn-amazon;
    }
    .btn-action-flat {
     @apply btn-amazon-flat;
    }

    .btn-action-secondary {
      @apply btn-amazon-gray;
    }
  }
}