@layer components {
  .dropzone {
    @apply flex flex-col items-center p-8 border-2 rounded-sm border-neutral-300 border-dashed bg-neutral-50 text-neutral-400 outline-none cursor-pointer;
    @apply transition-all ease-in-out duration-300;

    &:focus,
    &:hover,
    &.dropzone-active {
      @apply text-neutral-500 border-primary;
    }
  }
  .form-error .dropzone {
    @apply text-neutral-500 border-error;
  }

  .dropzone-thumbs {
    @apply flex flex-wrap flex-row gap-3 mt-2;

    .dropzone-thumb {
      @apply relative pt-[0.6rem] pr-[0.6rem] pb-10;

      .dropzone-preview {
        @apply rounded-md h-60 flex items-center justify-center bg-neutral-100 overflow-hidden;

        > img {
          @apply h-60;
        }

        > svg {
          @apply h-20 mx-16;
        }
      }

      .dropzone-name {
        @apply absolute bottom-1 left-[1rem] right-[1.4rem] overflow-hidden text-center whitespace-nowrap;
      }

      .dropzone-close {
        @apply btn btn-primary;
        @apply absolute w-auto h-auto top-0 right-0 rounded-full p-0 m-0 min-h-0;

        svg {
          @apply h-[1.7rem] w-[1.7rem];
        }
      }
    }
  }
}

//
//const thumbsContainer = {
//  display: 'flex',
//  flexDirection: 'row',
//  flexWrap: 'wrap',
//  marginTop: 16
//};
//
//const thumb = {
//  display: 'inline-flex',
//  borderRadius: 2,
//  border: '1px solid #eaeaea',
//  marginBottom: 8,
//  marginRight: 8,
//  width: 100,
//  height: 100,
//  padding: 4,
//  boxSizing: 'border-box'
//};
//
//const thumbInner = {
//  display: 'flex',
//  minWidth: 0,
//  overflow: 'hidden'
//};
//
//const img = {
//  display: 'block',
//  width: 'auto',
//  height: '100%'
//};