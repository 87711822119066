@layer components {
  .input, select, textarea {
    @apply text-neutral-600 mt-1 text-[1.1rem];

    &:disabled {
      @apply text-opacity-75;
    }

    &::placeholder,
    input::placeholder,
    textarea::placeholder {
      @apply text-neutral-500;
    }
  }

  .select {
    @apply mt-0;
  }

  .input-xmd {
    @apply input-md text-[1.2rem] h-14 px-5;
  }
  .input-lg {
    @apply text-[1.3rem];
  }

  .input:focus,
  .input:focus-within,
  .textarea:focus,
  .textarea:focus-within,
  .select:focus,
  .select:focus-within {
    @apply outline-1 outline-primary outline-offset-[-1px];
  }
}